<template>
  <b-modal
    id="cms-create-banner"
    title="New Banner"
    no-close-on-backdrop
    @ok="onOk"
  >
    <validation-observer
      ref="cmsBannerForm"
    >
      <b-form @submit.prevent="onSubmit">
        <validation-provider
          v-slot="{ errors }"
          name="title"
          rules=""
        >
          <b-form-group label="Title">
            <b-form-input
              id="title"
              v-model="createBannerForm.title"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="image_src"
          rules="required"
        >
          <b-form-group label="Image">
            <CmsFileSelect
              :value="createBannerForm.imageSrc"
              :state="errors[0] ? false : null"
              @update="(value) => (createBannerForm.imageSrc = value || '')"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="position"
          rules="required"
        >
          <b-form-group label="Position">
            <b-form-input
              id="position"
              v-model="createBannerForm.position"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from '@validations'
export default {
  components: {
    CmsFileSelect: () => import('../file-select.vue'),
  },
  data() {
    return {
      required,
      createBannerForm: {
        position: 0,
        title: '',
        imageSrc: '',
      },
    }
  },
  computed: {
    ...mapState({
      isCreatingSiteBanners: (state) => state.cms.isCreatingSiteBanners,
    }),
    ...mapGetters(['siteId']),
  },
  methods: {
    ...mapActions(['createCmsBanners']),
    async onOk(e) {
      e.preventDefault()
      const valid = await this.$refs.cmsForm.validate()
      if (valid) {
        this.onSubmit(() => this.$bvModal.hide('cms-create-banner'))
      }
    },
    async onSubmit(cb) {
      this.$refs.cmsBannerForm.validate().then( async (success) => {
        if (success) {
          await this.createCmsBanners({
            siteId: this.siteId,
            banners: [this.createBannerForm],
          })

          cb()
        }
      })
    },
  },
}
</script>
